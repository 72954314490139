import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { pipe, pick, evolve, isNil } from "ramda";

import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import Button from "../../design/Button";
import { presence } from "../../../lib/validations";
import { Context } from "../../../lib/Snackbar";
import { updateSweepstakesMutation } from "./gql";
import * as UI from "./ui";

const buildInitialInputObject = ({ prize: { name } }) => ({ prize: { name } });

const validations = {
  "prize.name": presence,
};

const PrizeForm = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateSweepstakes ] = useMutation(updateSweepstakesMutation)

  const buildSubmissionObject = sweepstakes.approved ?
    pipe(pick(['prize', 'threshold']), evolve({ prize: pick(['name'])})) :
    pipe(pick(['prize', 'threshold']), evolve({ prize: pick(['name', 'cashAlternative'])}));

  const onSubmit = inputObject => (
    updateSweepstakes({
      variables: {
        id: sweepstakes.id,
        attributes: buildSubmissionObject(inputObject),
      }
    }).then(({ data }) => {
      if(isNil(data.updateSweepstakes.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      } else {
        addMessage({ message: "There was a problem updating your sweepstakes.", type: "error" });
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(sweepstakes)} onSubmit={onSubmit} validations={validations}>
          <TextField id="prize.name" label="Prize Name" disabled={sweepstakes.approved} />
          <Button type="submit" disabled={sweepstakes.approved}>Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Understanding Prize Details</h2>
        <p>Your <strong>Prize Name</strong> should be short. It is primarily used in the FAQ and the rules for this sweepstakes.</p>
        <p><strong>Note:</strong> These values cannot be changed once your sweepstakes has been approved.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default PrizeForm;
