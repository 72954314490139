import React, { useCallback, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { isNil, path, pick, pipe, reject } from "ramda";
import { DateTime } from "luxon";

import ThankYouCampaignForm from "../../ThankYouCampaignForm";
import { Context as SnackbarContext } from "../../../lib/Snackbar";
import { updateThankYouCampaignMutation, promoCodesQuery } from "../gql";

const buildInitialInputObject = pipe(
  pick(['id', 'beginsAt', 'endsAt', 'notes', 'promoCode']),
  ({ beginsAt, endsAt, promoCode, ...rest }) => ({ ...rest, bonusPercent: promoCode?.bonusPercent, promoCodeName: promoCode?.name, range: [beginsAt, endsAt] }),
  reject(isNil),
);

const EditForm = ({ thankYouCampaign, sweepstakes, setMode, guarded }) => {
  const { data } = useQuery(promoCodesQuery,
    { variables: { sweepstakesId: sweepstakes.id } }
  );
  const [ updateThankYouCampaign, { loading } ] = useMutation(updateThankYouCampaignMutation);
  const { addMessage } = useContext(SnackbarContext);

  const hasBegun = DateTime.fromISO(thankYouCampaign.beginsAt) < DateTime.local()

  const buildSubmissionObject = hasBegun ?
    pick(['notes']) :
    pipe(
      pick(['range', 'notes', 'promoCodeName']),
      ({ range, ...rest }) => ({ ...rest, beginsAt: range[0], endsAt: range[1] }),
    );
  const onSubmit = useCallback(inputObject => (
    updateThankYouCampaign({
      variables: {
        id: thankYouCampaign.id,
        attributes: buildSubmissionObject(inputObject),
      },
    }).then(({ data }) => {
      const thankYouCampaign = path(['updateThankYouCampaign', 'thankYouCampaign'], data);

      if(thankYouCampaign) {
        setMode("show");
      } else {
        addMessage({ type: "error", message: "An error occurred during form submission." });
      }
    })
  ), [ addMessage, updateThankYouCampaign, setMode, thankYouCampaign, buildSubmissionObject ])

  return (
    <ThankYouCampaignForm
      sweepstakes={sweepstakes}
      promoCodes={data?.sweepstakes?.promoCodes}
      initialInputObject={buildInitialInputObject(thankYouCampaign)}
      onSubmit={onSubmit}
      zone={sweepstakes.timezone}
      hasBegun={hasBegun}
      loading={loading}
      guarded={guarded}
    />
  );
};

export default EditForm;
