import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { pipe, pick, evolve, isNil } from "ramda";

import { updateNonprofitMutation, sweepstakesQuery } from "./gql";
import Formation from "../../forms/Form";
import ImageUploadField from "../../forms/ImageUploadField";
import RichTextareaField from "../../forms/RichTextareaField";
import Button from "../../design/Button";
import { Context } from "../../../lib/Snackbar";
import * as UI from "./ui";

const profileImagePreview = {
  aspectRatio: "16:9",
  width: "460",
  fetchFormat: "auto",
};

const CROP_OPTIONS = [
  {
    id: 'fill',
    name: 'Crop to Fit',
    strategy: {
      crop: 'fill',
      gravity: 'auto',
      background: null,
    },
  },
  {
    id: 'lpad',
    name: 'No Crop',
    strategy: {
      crop: 'lpad',
      gravity: null,
      background: 'auto',
    }
  },
]

const evolveImageType = value => isNil(value) ? value : pipe(
  pick(['cloudName', 'publicId', 'version', 'crop', 'gravity', 'background']),
  JSON.stringify,
)(value);

const buildInitialInputObject = pipe(
  pick(['profile']),
  evolve({
    profile: pipe(
      pick(['description', 'image']),
      evolve({
        image: evolveImageType,
      }),
    ),
  })
);

const ProfileForm = ({ sweepstakes: { nonprofit }, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateNonprofit ] = useMutation(updateNonprofitMutation, {
    refetchQueries: [ sweepstakesQuery ],
  })

  const onSubmit = attributes => (
    updateNonprofit({
      variables: {
        id: nonprofit.id,
        attributes,
      }
    }).then(({ data }) => {
      if(isNil(data.updateNonprofit.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(nonprofit)} onSubmit={onSubmit}>
          <RichTextareaField id="profile.description" label="About Your Nonprofit" placeholder="Describe your nonprofit here..." />
          <ImageUploadField id="profile.image" label="Profile Image" owner={nonprofit.id} previewProps={profileImagePreview} crops={CROP_OPTIONS} />
          <Button type="submit">Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Nonprofit Profile</h2>
        <p>For your <strong>Description</strong>, describe your nonprofit in two short paragraphs. Start with a clear mission statement, then briefly share an overview of your nonprofit, including who you help and the difference you make in the world.</p>
        <p>Upload a <strong>Profile Image</strong> that represents your nonprofit. This could be a photo of your team, an event, or the people you serve.</p>
        <p><strong>Note:</strong> These settings are shared by all of your sweepstakes. You can change these settings at any time and they will update everywhere.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default ProfileForm;
