import React from "react";
import { DateTime } from "luxon";

import useField from "../../lib/useField";
import * as UI from "./ui";

// Datetimes are stored as ISO8601 strings in UTC time.
const forStorage = ({ value, zone }) => DateTime.fromISO(value).setZone(zone, { keepLocalTime: true }).toUTC().toISO()

// datetime-local inputs don't understand timezones, so all values are assumed to be in the sweepstakes' timezone.
const forDisplay = ({ value, zone }) => {
  if(!value) {
    return "";
  }

  return DateTime.fromISO(value, { zone })
    // Unset seconds and milliseconds to avoid the browser's datetime-local input from showing them.
    // Users should not be able to set seconds or milliseconds.
    .set({ seconds: 0, millisecond: 0 })
    // datetime-local is picky about the format, so hide seconds, milliseconds, and the timezone offset from the ISO string.
    .toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true });
}

const DateTimeField = ({id, label, zone, minDate, maxDate, ...rest}) => {
  const { value, errors, showErrors, skeleton, setValue, touch } = useField(id);

  const handleChange = e => {
    const value = forStorage({ value: e.target.value, zone });
    setValue({ target: { value }});
  };

  return (
    <UI.FieldLayout>
      <UI.Input
        id={id}
        type="datetime-local"
        name={id}
        value={forDisplay({ value, zone })}
        onChange={handleChange}
        onBlur={touch}
        disabled={skeleton}
        $invalid={showErrors}
        min={forDisplay({ value: minDate, zone })}
        max={forDisplay({ value: maxDate, zone })}
        data-intercom-target={`${id}*`}
        {...rest}
      />
      <UI.Label htmlFor={id}>{label}</UI.Label>
      { showErrors && <UI.Errors>{errors}</UI.Errors> }
    </UI.FieldLayout>
  );
};

export default DateTimeField;
