import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { includes, pathOr, filter } from "ramda";

import MasterDetail from "../../lib/MasterDetail";
import { useSchedulePeriod } from "../../lib/schedule";
import { thankYouCampaignsQuery } from "./gql";
import Item from "./Item";
import Detail from "./Detail";
import NoSelection from "./NoSelection";

const ThankYouCampaignList = ({ sweepstakesId }) => {
  const [ searchInput, setSearchInput ] = useState(null);

  const period = useSchedulePeriod();
  const newItemEnabled = ["review", "open", "closing"].includes(period);

  const { loading, data } = useQuery(thankYouCampaignsQuery, {
    variables: {
      sweepstakesId,
    }
  });

  const thankYouCampaigns = pathOr([], ["sweepstakes","thankYouCampaigns"], data);
  const sweepstakes = pathOr([], ["sweepstakes"], data);

  const search = {
    auto: true,
    placeholder: "Search by name…",
    onSubmit: setSearchInput,
  };

  const searchFilter = thankYouCampaign => (
    searchInput.value ? includes(searchInput.value.toLowerCase(), thankYouCampaign.name.toLowerCase()) : true
  );

  const searchedthankYouCampaigns = searchInput ? filter(searchFilter, thankYouCampaigns) : thankYouCampaigns;
  const newItem = {
    label: "Add Thank You Campaign",
    resource: "thankYouCampaigns",
    permission: "C",
    disabled: !newItemEnabled,
  };

  return (
    <MasterDetail
      items={searchedthankYouCampaigns}
      loading={loading}
      search={search}
      ListItemComponent={Item}
      DetailComponent={Detail}
      NoSelectionComponent={NoSelection}
      newItem={newItem}
      sweepstakes={sweepstakes}
    />
  );
}

export default ThankYouCampaignList;
