import React from "react";
import { Routes, Route } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

import { nonprofitQuery } from "./gql";
import * as UI from "./ui";

const Title = ({ children }) => (
  <React.Fragment>
    <Helmet>
      <title>{children} - TapKat Dashboard</title>
    </Helmet>
    <UI.Title>{children}</UI.Title>
  </React.Fragment>
);

const Header = ({ nonprofitId }) => {
  const { data } = useQuery(nonprofitQuery, {
    variables: { id: nonprofitId },
  });

  const nonprofit = data?.nonprofit;

  return (
    <UI.Header>
      <UI.Heading>
        <Routes>
          <Route index element={<Title>Team</Title>} />
          <Route path="invoices" element={<Title>Manual Donation Invoices</Title>} />
          <Route path="preferences" element={<Title>Email Preferences</Title>} />
        </Routes>
        <UI.Name>{nonprofit?.name}</UI.Name>
      </UI.Heading>
    </UI.Header>
  );
};

export default Header;
