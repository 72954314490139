import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { isNil } from "ramda";

import { updateNonprofitMutation, sweepstakesQuery } from "./gql";
import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import Button from "../../design/Button";
import { isLink, optional } from "../../../lib/validations";
import { Context } from "../../../lib/Snackbar";
import * as UI from "./ui";

const validations = {
  "profile.privacyPolicyWebsite": optional(isLink),
};

const buildInitialInputObject = ({ profile: { privacyPolicyWebsite } }) => ({ profile: { privacyPolicyWebsite } });

const PrivacyPolicyForm = ({ sweepstakes: { nonprofit }, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateNonprofit ] = useMutation(updateNonprofitMutation, {
    refetchQueries: [ sweepstakesQuery ],
  })

  const onSubmit = attributes => (
    updateNonprofit({
      variables: {
        id: nonprofit.id,
        attributes,
      }
    }).then(({ data }) => {
      if(isNil(data.updateNonprofit.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(nonprofit)} onSubmit={onSubmit} validations={validations}>
          <TextField id="profile.privacyPolicyWebsite" label="Privacy Policy Website" />
          <Button type="submit">Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Your Privacy Policy</h2>
        <p>Provide an optional link to the Privacy Policy page on your nonprofit’s website. This is a requirement for many email and texting platforms. This link will be added to the footer of your TapKat sweepstakes site.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default PrivacyPolicyForm;
