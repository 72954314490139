import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { pipe, pick, evolve, isNil } from "ramda";

import { updateNonprofitMutation, sweepstakesQuery } from "./gql";
import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import StateSelectField from "../../forms/StateSelectField";
import CountrySelectField from "../../forms/CountrySelectField";
import Button from "../../design/Button";
import { presence, isLink } from "../../../lib/validations";
import { Context } from "../../../lib/Snackbar";
import * as UI from "./ui";

const validations = {
  "profile.phone": presence,
  "profile.email": presence,
  "profile.website": isLink,
  "address.line1": presence,
  "address.city": presence,
  "address.state": presence,
  "address.postalCode": presence,
  "address.country": presence,
};

const buildInitialInputObject = pipe(
  pick(['profile', 'address']),
  evolve({
    profile: pick(['email', 'phone', 'website']),
    address: pick(['line1', 'line2', 'city', 'state', 'postalCode', 'country']),
  })
);

const NonprofitContactInfoForm = ({ sweepstakes: { nonprofit }, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateNonprofit  ] = useMutation(updateNonprofitMutation, {
    refetchQueries: [ sweepstakesQuery ],
  })

  const onSubmit = attributes => (
    updateNonprofit({
      variables: {
        id: nonprofit.id,
        attributes,
      }
    }).then(({ data }) => {
      if(isNil(data.updateNonprofit.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(nonprofit)} onSubmit={onSubmit} validations={validations}>
          <UI.FieldGroup>
            <TextField id="profile.phone" label="Public Phone" />
            <TextField id="profile.email" label="Public Email" />
          </UI.FieldGroup>
          <TextField id="profile.website" label="Website" />
          <UI.FieldGroup>
            <TextField id="address.line1" label="Address Line 1" />
            <TextField id="address.line2" label="Address Line 2" />
          </UI.FieldGroup>
          <UI.FieldGroup>
            <TextField id="address.city" label="City" />
            <StateSelectField id="address.state" label="State" />
            <UI.PostalCodeContainer>
              <TextField id="address.postalCode" label="Postal Code" />
            </UI.PostalCodeContainer>
          </UI.FieldGroup>
          <CountrySelectField id="address.country" label="Country" disabled />
          <Button type="submit">Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Nonprofit Address</h2>
        <p>Your <strong>Contact Phone</strong> is a public phone number that entrants can call to get help or learn more about your nonprofit.</p>
        <p>Provide a <strong>Contact Email</strong> visible to the public that entrants can use to get help or learn more about your nonprofit.</p>
        <p>Your <strong>Website</strong> is your nonprofit's homepage. This is a public link that entrants can use to learn more about your nonprofit.</p>
        <p>Your <strong>Address</strong> is the primary public address of your nonprofit. This may or may not be the same as the Mailing Address for entrants to send donations by mail.</p>
        <p><strong>Note:</strong> These settings are shared by all of your sweepstakes. You can change these settings at any time and they will update everywhere.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default NonprofitContactInfoForm;
