import React, { useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { prepend, isNil, evolve, path } from "ramda";

import { Context as SnackbarContext } from "../../../lib/Snackbar";
import ThankYouCampaignForm from "../../ThankYouCampaignForm";
import { thankYouCampaignsQuery, createThankYouCampaignMutation, promoCodesQuery } from "../gql";

const prependOnSuccess = sweepstakesId => (cache, results) => {
  const thankYouCampaign = path(['data', 'createThankYouCampaign', 'thankYouCampaign'], results);

  if(isNil(thankYouCampaign)) {
    return;
  }

  const config = {
    query: thankYouCampaignsQuery,
    variables: { sweepstakesId },
  };

  const data = cache.readQuery(config);

  const evolution = {
    sweepstakes: {
      thankYouCampaigns: prepend(thankYouCampaign),
    }
  };

  cache.writeQuery({
    ...config,
    data: evolve(evolution, data),
  });
};

const NewForm = ({ guarded, sweepstakes, onSelect }) => {
  const { data } = useQuery(promoCodesQuery,
    { variables: { sweepstakesId: sweepstakes.id } }
  );

  const [ createThankYouCampaign, { loading } ] = useMutation(createThankYouCampaignMutation, {
    update: prependOnSuccess(sweepstakes.id),
  });
  const { addMessage } = useContext(SnackbarContext);

  useEffect(() => {
    if (guarded) {
      addMessage({ type: "error", message: "The Sweepstakes has closed so you can not add new Flash Contests." })
    }
  }, [ addMessage, guarded ]);

  const onSubmit = ({ range, ...rest }) => (
    createThankYouCampaign({
      variables: {
        sweepstakesId: sweepstakes.id,
        attributes: {
          ...rest,
          beginsAt: range[0],
          endsAt: range[1],
        }
      },
    }).then(({ data }) => {
      const thankYouCampaign = path(['createThankYouCampaign', 'thankYouCampaign'], data);

      if(thankYouCampaign) {
        onSelect(thankYouCampaign.id);
      } else {
        addMessage({ type: "error", message: "An error occurred during form submission." });
      }
    })
  );

  return (
    <ThankYouCampaignForm
      sweepstakes={sweepstakes}
      promoCodes={data?.sweepstakes?.promoCodes}
      initialInputObject={{}}
      onSubmit={onSubmit}
      zone={sweepstakes.timezone}
      loading={loading}
      guarded={guarded}
    />
  );
};

export default NewForm;
