import React, { useState } from "react";
import { v4 as uuid } from "uuid"; // Switch to useId when available

import SelectField from "../forms/SelectField";
import useField from "../../lib/useField";
import { formatAsDollars } from "../../util/money";
import * as UI from "../forms/ui";

const DonationLevelIdField = ({ id, donationLevels, disabled, ...rest }) => {
  const { value, setValue } = useField(id);
  const [ enabled, setEnabled ] = useState(!!value)
  const checkboxId = uuid();

  const options = donationLevels.map(donationLevel => ({
    id: donationLevel.id,
    name: `${formatAsDollars(donationLevel.price)} worth of entries (${donationLevel.rewardTickets})`,
  }));

  const toggle = () => {
    if(enabled) {
      // If we are disabling the field, clear the value
      setValue({ target: { value: null } });
    } else {
      // If we are enabling the field, set the value to the first option
      setValue({ target: { value: options[0].id } });
    }

    setEnabled(enabled => !enabled)
  }

  return (
    <React.Fragment>
      <UI.FieldLayout>
        <UI.Checkbox id={checkboxId} checked={enabled} onChange={toggle} data-intercom-target="Enable Donation Level Select" disabled={disabled} />
        <UI.CheckboxLabel htmlFor={checkboxId}>I want to give entries to the winner</UI.CheckboxLabel>
      </UI.FieldLayout>
      { enabled && <SelectField id={id} label="Prize Entries" options={options} disabled={disabled} {...rest} /> }
    </React.Fragment>
  );
};

export default DonationLevelIdField;
