import React from "react";
import { useParams } from "react-router-dom";

import { withAccessControl } from "../../lib/permissions";
import FlashContestList from "../FlashContestList";

const FlashContestPage = () => {
  const { sweepstakesId } = useParams();
  return <FlashContestList sweepstakesId={sweepstakesId} />
}

export default withAccessControl({ resource: "flashContests" })(FlashContestPage);
