import React from "react";

import * as UI from "./ui";

const Logo = () => (
  <UI.LogoLink to="/">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.4 90.4">
      <g transform="translate(-10.2 -7.3)">
        <ellipse cx="110.1" cy="55.7" fill="#99ceec" rx="10.7" ry="17.9" transform="rotate(-21.188 110.133 55.689)"></ellipse>
        <ellipse cx="65.3" cy="64.1" fill="#ffb805" rx="14" ry="6.5" transform="rotate(-21.188 65.329 64.09)"></ellipse>
        <ellipse cx="212" cy="55.4" fill="#93d500" rx="14.1" ry="7.8" transform="rotate(-21.188 212.03 55.396)"></ellipse>
        <g fill="#1d4f90">
          <path d="M48.7 38.8c-.5-.9-1-1.8-1.4-2.8-.4-1-.6-1.8-.6-2.4 0-1.1.9-2.2 2.8-3.2 1.8-1 4.1-1.8 6.9-2.4 2.8-.6 5.5-.9 8.2-.9 6.4 0 11 1.4 13.9 4.1 2.9 2.7 4.3 7.3 4.3 13.7v20c0 1.9.1 3.3.3 4.3.2 1 .6 1.7 1.2 2 .6.4 1.7.6 3.5.6v3.9c-1.4.5-3.1.7-5 .7-5 0-7.8-1.9-8.3-5.8-2.2 2.2-4.5 3.7-7 4.7-2.5.9-5.3 1.4-8.5 1.4-4.8 0-8.6-1.2-11.3-3.6-2.7-2.4-4-5.9-4-10.5 0-5.4 2.3-9.6 6.9-12.5 4.6-2.9 11-4.5 19.3-4.8 2 0 3.5 0 4.3.1v-1.5c0-3.4-.8-5.8-2.5-7.2-1.7-1.4-4.4-2.1-8.1-2.1-2.6 0-5 .3-7.2 1-2.1.6-4.4 1.8-6.7 3.4h-1v-.2zm25.6 13h-3c-6.1 0-10.9.9-14.1 2.6-3.2 1.7-4.8 4.3-4.8 7.6 0 2.5.7 4.4 2.2 5.7 1.5 1.3 3.6 2 6.5 2 2.2 0 4.4-.4 6.5-1.2 2.1-.8 3.7-1.8 4.9-3 1.2-1.2 1.8-2.3 1.8-3.3z"></path>
          <path d="M95 28c.7-.1 1.3-.1 1.9-.1 3.7 0 5.9 1.6 6.6 4.9 1.5-1.8 3.4-3.2 5.9-4.2 2.4-1 5.1-1.5 8.2-1.5 6.2 0 11 2.1 14.4 6.3 3.4 4.2 5.1 10.2 5.1 18 0 5.6-.8 10.3-2.5 14.1-1.7 3.8-4.1 6.7-7.2 8.8-3.1 2-6.8 3-10.9 3-2.9 0-5.4-.5-7.7-1.5s-4-2.2-5.2-3.8v25.7h-8.7zm8.7 36.4c1.2 1.6 2.9 3 5 4s4.3 1.6 6.5 1.6c4.1 0 7.3-1.5 9.5-4.6 2.2-3 3.3-7.5 3.3-13.5 0-11.7-4.3-17.5-12.9-17.5-3.2 0-5.9.7-8.1 2-2.2 1.4-3.3 3-3.3 4.9z"></path>
          <path d="M195.8 38.8c-.5-.9-1-1.8-1.4-2.8-.4-1-.6-1.8-.6-2.4 0-1.1.9-2.2 2.8-3.2 1.8-1 4.1-1.8 6.9-2.4 2.8-.6 5.5-.9 8.2-.9 6.4 0 11 1.4 13.9 4.1 2.9 2.7 4.3 7.3 4.3 13.7v20c0 1.9.1 3.3.3 4.3.2 1 .6 1.7 1.2 2 .6.4 1.7.6 3.5.6v3.9c-1.4.5-3.1.7-5 .7-5 0-7.8-1.9-8.3-5.8-2.2 2.2-4.5 3.7-7 4.7-2.5.9-5.3 1.4-8.5 1.4-4.8 0-8.6-1.2-11.3-3.6-2.7-2.4-4-5.9-4-10.5 0-5.4 2.3-9.6 6.9-12.5 4.6-2.9 11-4.5 19.3-4.8 2 0 3.5 0 4.3.1v-1.5c0-3.4-.8-5.8-2.5-7.2-1.7-1.4-4.4-2.1-8.1-2.1-2.6 0-5 .3-7.2 1-2.1.6-4.4 1.8-6.7 3.4h-1zm25.6 13h-3c-6.2 0-10.9.9-14.1 2.6-3.2 1.7-4.8 4.3-4.8 7.6 0 2.5.7 4.4 2.2 5.7 1.5 1.3 3.6 2 6.5 2 2.2 0 4.4-.4 6.5-1.2 2.1-.8 3.7-1.8 4.9-3 1.2-1.2 1.8-2.3 1.8-3.3z"></path>
          <path d="M241.9 13.6c.6-.1 1.2-.1 1.8-.1 4.6 0 6.8 2.3 6.8 6.9v7.8h15.2v7.3h-15.2v26.1c0 5.5 2.2 8.3 6.6 8.3 1.2 0 2.3-.2 3.4-.7 1.1-.5 2.2-1.1 3.1-1.8 1-.7 1.6-1.4 1.9-1.8 2 2.4 3 4.3 3 5.7 0 .9-.6 1.9-1.9 2.9-1.3 1-2.9 1.8-4.7 2.3-1.9.5-3.9.8-6.1.8-6.8 0-11.1-2.5-12.9-7.6-.7-1.9-1-4.8-1-8.7z"></path>
          <path d="M10.2 13.6c.6-.1 1.2-.1 1.8-.1 4.6 0 6.8 2.3 6.8 6.9v7.8H34v7.3H18.9v26.1c0 5.5 2.2 8.3 6.6 8.3 1.2 0 2.3-.2 3.4-.7 1.1-.5 2.2-1.1 3.1-1.8 1-.7 1.6-1.4 1.9-1.8 2 2.4 3 4.3 3 5.7 0 .9-.6 1.9-1.9 2.9-1.3 1-2.9 1.8-4.7 2.3-1.9.5-3.9.8-6.1.8-6.8 0-11.1-2.5-12.9-7.6-.7-1.9-1-4.8-1-8.7z"></path>
        </g>
        <g>
          <path fill="#1d4f90" d="M146.7 7.4c.6-.1 1.2-.1 1.8-.1 4.4 0 6.7 2.3 6.7 6.9v62.1h-8.5zm7.8 43v-3.5L173.7 29c2.2-1.8 4.3-2.7 6.4-2.7 2.1 0 4 .7 5.7 2.2l-21.5 19.8 24.8 27.8c-1.8.6-3.7.9-5.6.9-3.4 0-6.1-1.3-8.4-3.8z"></path>
        </g>
        <g fill="#1d4f90">
          <path d="M279.2 28.1v1.4h-3.9v10.1h-1.6V29.5h-3.9v-1.4z"></path>
          <path d="M280.6 28.1h2.2l3.3 9.7 3.3-9.7h2.2v11.5h-1.5v-9.7l-3.3 9.7h-1.5l-3.3-9.7v9.8h-1.5z"></path>
        </g>
      </g>
    </svg>
  </UI.LogoLink>
);

export default Logo;
