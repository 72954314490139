/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useContext } from "react";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { find, isNil, propEq } from "ramda";
import { useLocation } from "react-router-dom";

import MasterList from "./MasterList";
import MasterFooter from "./MasterFooter";
import Detail from "./Detail";
import Search from "./Search";
import * as UI from "./ui";

const MasterDetailContext = createContext();

export const useMasterDetailContext = () => {
  return useContext(MasterDetailContext);
}

export const NEW_ITEM = { id: "__NEW__" };

const modeFor = (width, selectedItem) => {
  if(width >= 768) {
    return "side-by-side";
  }

  return selectedItem ? "detail-only" : "master-only";
}

const findSelectedItem = (items, id) => id === NEW_ITEM.id ? NEW_ITEM : find(propEq(id, 'id'), items);

const MasterDetail = props => {
  const {
    items,
    search,
    loading,
    newItem,
    footerMenu = null,
    ListItemComponent,
    DetailComponent,
    EmptyComponent,
    NoSelectionComponent,
    ...rest
  } = props;

  const width = useWindowWidth();
  const location = useLocation();

  const [ selectedItemId, setSelectedItemId ] = useState(location.state?.selectedItemId || null);
  const [ locked, setLocked ] = useState(false);
  const [ searchTerm, setSearchTerm ] = useState("");
  const [ filter, setFilter ] = useState({ id: search?.defaultFilter });

  const selectedItem = findSelectedItem(items, selectedItemId);
  const mode = modeFor(width, selectedItem);

  const showEmptyComponent = !loading && items.length === 0 && !isNil(EmptyComponent);
  const showFooter = newItem;

  const contextValue = {
    ...rest,
    locked,
    setLocked,
    searchTerm,
    filter,
  };

  return (
    <MasterDetailContext.Provider value={contextValue}>
      <UI.Layout>
        <UI.MasterLayout $mode={mode}>
          { mode === "side-by-side" && <UI.Lock $locked={locked} /> }
          { search && <Search
            search={search}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filter={filter}
            setFilter={setFilter}
          />}
          { showEmptyComponent ? (
            <EmptyComponent />
          ) : (
            <MasterList
              items={items}
              selectedItem={selectedItem}
              onSelect={setSelectedItemId}
              mode={mode}
              ListItemComponent={ListItemComponent}
            />
          )}
          { showFooter && (
            <MasterFooter
              newItem={{ ...newItem, onClick: () => { setSelectedItemId(NEW_ITEM.id) }}}
              footerMenu={footerMenu}
            />
          )}
        </UI.MasterLayout>
        <Detail
          item={selectedItem}
          mode={mode}
          onSelect={setSelectedItemId}
          DetailComponent={DetailComponent}
          onCancel={() => setSelectedItemId(null)}
          NoSelectionComponent={NoSelectionComponent}
        />
      </UI.Layout>
    </MasterDetailContext.Provider>
  );
};

export default MasterDetail;
