import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = styled(FontAwesomeIcon)`
  ${props => props.$size === "tiny" && `
    margin: 0 6px 0 -3px;
  `}

  ${props => props.$size === "small" && `
    margin: 0 6px 0 -3px;
  `}

  ${props => props.$size === "medium" && `
    margin: 0 12px 0 -6px;
    font-size: 1.25em;
  `}

  ${props => props.$size === "large" && `
    margin: 0 12px 0 -12px;
  `}
`;

const Element = styled(NavLink)`
  border-radius: 3px;
  display: inline-flex;
  background: #fff;
  color: #444;
  font-weight: bold;
  font-size: 0.875em;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  align-items: center;

  &.active {
    text-decoration-color: #0077cc;
    ${Icon} {
      color: #0077cc;
    }
  }

  ${props => props.$disabled && `
    pointer-events: none;
    opacity: 0.5;
  `}

  ${props => props.$size === "tiny" && `
    padding: 3px 12px;
    font-size: 0.6875em;
  `}

  ${props => props.$size === "small" && `
    padding: 6px 12px;
    font-size: 0.875em;
  `}

  ${props => props.$size === "medium" && `
    padding: 12px 24px;
    font-size: 0.875em;
  `}

  ${props => props.$size === "large" && `
    padding: 24px 36px;
    font-size: 1.125em;
  `}
`;

const Label = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`;

const HotkeyHint = styled.span`
  font-size: 0.75em;
  border: 1px solid currentcolor;
  border-radius: 3px;
  text-align: center;
  height: 18px;
  width: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  margin-right: -6px;
`;

const Link = ({ to, href, icon, size = "medium", hotkey, disabled, children, ...rest }) => {
  const props = to ?
    { to } :
    { as: 'a', href, target: "_blank", rel: "noopener noreferrer" };

  return (
    <Element $size={size} $disabled={disabled} {...props} data-intercom-target={`${children}*`} {...rest}>
      {icon && <Icon icon={icon} $size={size} /> }
      <Label>{children}</Label>
      { hotkey && <HotkeyHint>{hotkey}</HotkeyHint> }
    </Element>
  );
};

export default Link;
