import React from "react";
import { useParams, Routes, Route } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";

import { ScheduleProvider } from "../../lib/schedule";
import { withAccessControl } from "../../lib/permissions";
import SweepstakesPage from "../SweepstakesPage";
import EntriesPage from "../EntriesPage";
import PromoCodePage from "../PromoCodePage";
import FlashContestPage from "../FlashContestPage";
import ThankYouCampaignPage from "../ThankYouCampaignPage";
import WinnerPage from "../WinnerPage";
import SweepstakesMarketingPage from "../SweepstakesMarketingPage";
import InsightsPage from "../InsightsPage";
import SweepstakesEditPage from "../SweepstakesEditPage";
import DonationPluginSetupGuidePage from "../DonationPluginSetupGuidePage";
import SweepstakesRetrospectivePage from "../SweepstakesRetrospectivePage";
import Header from "./Header";
import { pagesSetupQuery } from "./queries";
import Hotkeys from "./Hotkeys";
import * as UI from "./ui";

const SweepstakesPages = () => {
  const { sweepstakesId } = useParams();
  const { data } = useQuery(pagesSetupQuery, {
    variables: {
      sweepstakesId,
    }
  });

  const sweepstakes = pathOr({ publicUrl: "", prize: {}}, ["sweepstakes"], data);

  return (
    <ScheduleProvider sweepstakesId={sweepstakesId}>
      <Hotkeys />
      <UI.Layout>
        <Header sweepstakes={sweepstakes} />
        <UI.Content>
          <Routes>
            <Route index                      element={<SweepstakesPage sweepstakes={sweepstakes} /> } />
            <Route path="donations"           element={<EntriesPage />} />
            <Route path="promo-codes"         element={<PromoCodePage />} />
            <Route path="winner"              element={<WinnerPage />} />
            <Route path="insights"            element={<InsightsPage />} />
            <Route path="site/*"              element={<SweepstakesEditPage />} />
            <Route path="flash-contests"      element={<FlashContestPage />} />
            <Route path="thank-you-campaigns" element={<ThankYouCampaignPage />} />
            <Route path="marketing"           element={<SweepstakesMarketingPage sweepstakes={sweepstakes} />} />
            <Route path="plugin/*"            element={<DonationPluginSetupGuidePage />} />
            <Route path="retro"               element={<SweepstakesRetrospectivePage />} />
          </Routes>
        </UI.Content>
      </UI.Layout>
    </ScheduleProvider>
  );
};

export default withAccessControl({ resource: "sweepstakes" })(SweepstakesPages);
