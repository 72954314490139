import React, { useState } from "react";
import styled from "styled-components";

const Layout = styled.ol`
  display: flex;
  list-style-type: none;
  align-items: stretch;
`;

const Option = styled.li`
  display: inline-block;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  background-color: #ffffff;
  border: none;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  color: #333333;
  cursor: pointer;
  display: block;
  height: 100%;
  font-family: tondo;
  font-size: 0.6875em;
  font-weight: bold;
  padding: 3px 12px;

  ${Option}:first-child & {
    border-radius: 24px 0 0 24px;
  }

  ${Option}:last-child & {
    border-radius: 0 24px 24px 0;
  }

  ${Input}:checked + & {
    background-color: #f0f0f0;
    color: #0077cc;
  }

  ${Input}:disabled + & {
    background-color: #969696;
    pointer-events: none;
    color: #ffffff;
  }
`

const RadioButtonControl = ({ name, options, defaultOption, onChange, disabled }) => {
  const [ value, setValue ] = useState(defaultOption);

  const handleChange = e => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Layout>
      {options.map(option => (
        <Option key={option.id}>
          <Input
            type="radio"
            name={name}
            id={option.id}
            value={option.id}
            checked={option.id === value}
            onChange={handleChange}
            disabled={disabled}
          />
          <Label htmlFor={option.id}>{option.name}</Label>
        </Option>
      ))}
    </Layout>
  );
};

export default RadioButtonControl;
