import React, { useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useQuery } from "@apollo/client";
import { propEq, propOr, pathOr } from "ramda";

import { PermissionsProvider } from "../../lib/permissions";
import Intercom from "../../lib/Intercom";
import NonprofitMenu from "../NonprofitMenu";
import Logo from "./Logo";
import UserMenu from "../UserMenu";
import { nonprofitRolesQuery } from "./gql";
import * as UI from "./ui";

const AppLayout = ({ children }) => {
  const { nonprofitId } = useParams();
  const { data } = useQuery(nonprofitRolesQuery);

  const session = propOr(null, "session", data);
  const nonprofits = pathOr([], ["nonprofits"], data);
  const nonprofit = nonprofits.find(propEq(nonprofitId, "id"));
  const role = propOr(null, "myRole", nonprofit);

  useEffect(() => {
    if(!nonprofit || !window.Intercom) {
      return;
    }

    window.Intercom('update', {
      company: {
        id: nonprofit.id,
        name: nonprofit.name,
        website: nonprofit.profile.website,
      },
    });
  }, [ nonprofit ]);

  return (
    <PermissionsProvider role={role}>
      <Intercom />
      <UI.Layout>
        <UI.Header>
          <UI.HeaderItem $align="left">
            <NonprofitMenu nonprofit={nonprofit} />
          </UI.HeaderItem>
          <UI.HeaderItem>
            <Logo />
          </UI.HeaderItem>
          <UI.HeaderItem $align="right">
            <UserMenu nonprofits={nonprofits} currentNonprofit={nonprofit} session={session} />
          </UI.HeaderItem>
        </UI.Header>
        <UI.Content>
          <Outlet />
        </UI.Content>
      </UI.Layout>
    </PermissionsProvider>
  );
};

export default withAuthenticationRequired(AppLayout);
