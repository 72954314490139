import React from "react";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";

import { ScheduleMask } from "../../../lib/schedule";
import AccessControlledButton from "../../../lib/AccessControlledButton";
import Button from "../../design/Button";
import { showThankYouCampaignQuery } from "../gql";
import * as UI from "./ui";
import { shortDate } from "../../../util/date";

const Show = ({ thankYouCampaign: partialThankYouCampaign, sweepstakes, showCancel, onCancel, setMode }) => {
  const { data } = useQuery(showThankYouCampaignQuery, {
    variables: {
      sweepstakesId: sweepstakes.id,
      id: partialThankYouCampaign.id,
    }
  });

  const thankYouCampaign = pathOr(partialThankYouCampaign, ["sweepstakes", "thankYouCampaign"], data);
  const promoCode = thankYouCampaign.promoCode;

  return (
    <UI.Layout>
      <UI.Header>
        { showCancel && (
          <Button size="small" onClick={onCancel}>❮ Close</Button>
        )}
        <UI.HeaderActions>
          <ScheduleMask enabledPeriods={["review", "open", "closing", "grace", "closed"]}>
            <AccessControlledButton size="small" onClick={() => setMode("edit")} resource="thankYouCampaigns" permission="U">
              Edit Thank You Campaign
            </AccessControlledButton>
          </ScheduleMask>
        </UI.HeaderActions>
      </UI.Header>

      <UI.Body>
        <UI.Heading>
          <UI.Name>{thankYouCampaign.name}</UI.Name>
          <UI.Date>
            From {shortDate(sweepstakes, thankYouCampaign.beginsAt)} to {shortDate(sweepstakes, thankYouCampaign.endsAt)}
          </UI.Date>
        </UI.Heading>

        { promoCode && (
          <UI.Field>
            <UI.Label>Promo Code</UI.Label>
            <UI.ValueLink to="../promo-codes" state={{ selectedItemId: promoCode.id }}>{promoCode.name}</UI.ValueLink>
          </UI.Field>
        )}

        { thankYouCampaign.notes && (
          <UI.Field>
            <UI.Label>Notes</UI.Label>
            <UI.Value>{thankYouCampaign.notes}</UI.Value>
          </UI.Field>
        )}

        <UI.Field>
          <UI.Label>Small Device Image</UI.Label>
          <UI.SmallImage src={thankYouCampaign.smallImageUrl} />
        </UI.Field>
        <UI.Field>
          <UI.Label>Large Device Image</UI.Label>
          <UI.LargeImage src={thankYouCampaign.largeImageUrl} />
        </UI.Field>
      </UI.Body>
    </UI.Layout>
  );
}

export default Show;
