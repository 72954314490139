import React from "react";
import { is, map, isEmpty } from "ramda";

import useField from "../../lib/useField";
import * as UI from "./ui";

const Options = ({options}) => (
  map(option => {
    if(is(Object, option)) {
      return (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      );
    }

    if(is(String, option)) {
      return (
        <option key={option} value={option}>{option}</option>
      );
    }

    throw new TypeError("SelectField requires options be either a String or an Object");
  }, options)
);

const SelectField = ({id, label, options = [], allowBlank, ...rest}) => {
  const { value, errors, showErrors, skeleton, setValue, touch } = useField(id);

  const setWithCoercion = event => {
    // Capture event value early, as they are re-used.
    const eventValue = event.target.value;

    if(isEmpty(eventValue)) {
      setValue({target: {value: ""}});
    } else {
      const parsed = parseInt(eventValue, 10);
      // Default to original value if we couldn't parse the new input.
      const newValue = isNaN(parsed) ? value : parsed;
      setValue({target: { value: newValue } });
    }
  }

  return (
    <UI.FieldLayout>
      <UI.Select
        id={id}
        name={id}
        onChange={setWithCoercion}
        onBlur={touch}
        value={value}
        disabled={skeleton}
        $invalid={showErrors}
        data-intercom-target={`${id}*`}
        {...rest}
      >
        { allowBlank ? <option key={"__blank__"} value="" /> :null }
        <Options options={options} />
      </UI.Select>
      <UI.Label htmlFor={id}>{label}</UI.Label>
      { showErrors && <UI.Errors>{errors}</UI.Errors> }
    </UI.FieldLayout>
  );
}

export default SelectField;
