import React, { useCallback, useContext } from "react";
import { useMutation } from "@apollo/client";
import { isNil, path, pick, evolve, pipe, reject } from "ramda";
import { DateTime } from "luxon";

import FlashContestForm from "../../FlashContestForm";
import { Context as SnackbarContext } from "../../../lib/Snackbar";
import { updateFlashContestMutation } from "../gql";

const evolveImageType = value => isNil(value) ? value : pipe(
  pick(['cloudName', 'publicId', 'version', 'crop', 'gravity', 'background']),
  JSON.stringify,
)(value);

const buildInitialInputObject = pipe(
  pick(['id', 'name', 'announcement', 'beginsAt', 'endsAt', 'image', 'caption', 'notes', 'donationLevel']),
  ({ beginsAt, endsAt, donationLevel, ...rest }) => ({ ...rest, range: [beginsAt, endsAt], donationLevelId: donationLevel?.id }),
  evolve({
    image: evolveImageType,
  }),
  reject(isNil),
);

const pickSubmitionAttributesWithRange = pipe(
  pick(['name', 'announcement', 'range', 'image', 'caption', 'notes', 'donationLevelId']),
  ({ range, ...rest }) => ({ ...rest, beginsAt: range[0], endsAt: range[1] }),
);

const EditForm = ({ flashContest, sweepstakes, setMode, guarded }) => {
  const [ updateFlashContest, { loading } ] = useMutation(updateFlashContestMutation);
  const { addMessage } = useContext(SnackbarContext);

  const hasBegun = DateTime.fromISO(flashContest.beginsAt) < DateTime.local()

  const buildSubmissionObject = useCallback(inputObject => {
    if (flashContest.locked) {
      return pick(['notes'], inputObject);
    }

    if (hasBegun) {
      return pick(['name', 'announcement', 'beginsAt', 'endsAt', 'image', 'caption', 'notes', 'donationLevelId'], inputObject);
    }

    return pickSubmitionAttributesWithRange(inputObject);
  }, [hasBegun, flashContest.locked]);

  const onSubmit = useCallback(inputObject => (
    updateFlashContest({
      variables: {
        id: flashContest.id,
        attributes: buildSubmissionObject(inputObject),
      },
    }).then(({ data }) => {
      const flashContest = path(['updateFlashContest', 'flashContest'], data);

      if(flashContest) {
        setMode("show");
      } else {
        addMessage({ type: "error", message: "An error occurred during form submission." });
      }
    })
  ), [ addMessage, updateFlashContest, setMode, flashContest, buildSubmissionObject ])

  return (
    <React.Fragment>
      <FlashContestForm
        sweepstakes={sweepstakes}
        initialInputObject={buildInitialInputObject(flashContest)}
        onSubmit={onSubmit}
        zone={sweepstakes.timezone}
        hasBegun={hasBegun}
        loading={loading}
        guarded={guarded}
        locked={flashContest.locked}
      />
    </React.Fragment>
  );
};

export default EditForm;
