import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { isNil } from "ramda";

import { updateNonprofitMutation, sweepstakesQuery } from "./gql";
import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import Button from "../../design/Button";
import { isLink, optional } from "../../../lib/validations";
import { Context } from "../../../lib/Snackbar";
import * as UI from "./ui";

const validations = {
  "profile.newsletterWebsite": optional(isLink),
};

const buildInitialInputObject = ({ profile: { newsletterWebsite } }) => ({ profile: { newsletterWebsite } });

const ProfileForm = ({ sweepstakes: { nonprofit }, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateNonprofit  ] = useMutation(updateNonprofitMutation, {
    refetchQueries: [ sweepstakesQuery ],
  })

  const onSubmit = attributes => (
    updateNonprofit({
      variables: {
        id: nonprofit.id,
        attributes,
      }
    }).then(({ data }) => {
      if(isNil(data.updateNonprofit.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(nonprofit)} onSubmit={onSubmit} validations={validations}>
          <TextField id="profile.newsletterWebsite" label="Nonprofit Newsletter Opt-in Website" />
          <Button type="submit">Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Newsletter Signup</h2>
        <p>Provide an optional link to a landing page on your own website where users can sign up for your newsletter. If you don't have a newsletter or a sign up page, you can skip this step.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default ProfileForm;
