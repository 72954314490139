import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { pipe, pick, isNil } from "ramda";

import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import ClearFieldButton from "../../forms/ClearFieldButton";
import Button from "../../design/Button";
import { lengthOf } from "../../../lib/validations";
import { Context } from "../../../lib/Snackbar";
import { updateSweepstakesMutation } from "./gql";
import * as UI from "./ui";

const validations = {
  announcement: lengthOf({ maximum: 120 }),
};

const buildInitialInputObject = pipe(
  pick(['announcement']),
);

const AnnouncementForm = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateSweepstakes ] = useMutation(updateSweepstakesMutation)

  const onSubmit = inputObject => (
    updateSweepstakes({
      variables: {
        id: sweepstakes.id,
        attributes: inputObject,
      }
    }).then(({ data }) => {
      if(isNil(data.updateSweepstakes.error)) {
        addMessage({ message: "Your Promotional Banner has been posted.", type: "success" });
        onStepFinish();
      } else {
        addMessage({ message: data.updateSweepstakes.error });
      }
    })
  );

  const onClear = () => (
    updateSweepstakes({
      variables: {
        id: sweepstakes.id,
        attributes: { announcement: "" },
      }
    }).then(({ data }) => {
      if(isNil(data.updateSweepstakes.error)) {
        addMessage({ message: "Your Promotional Banner has been removed.", type: "success" });
      } else {
        addMessage({ message: data.updateSweepstakes.error });
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(sweepstakes)} onSubmit={onSubmit} validations={validations}>
          <TextField id="announcement" label="Announcement" />
          <UI.ButtonGroup>
            <Button type="submit">Save & Post</Button>
            <ClearFieldButton id="announcement" onClick={onClear} label="Remove" size="small" />
          </UI.ButtonGroup>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Adding a Promotional Banner</h2>
        <p>Use the promotional banner to feature a current promo code or flash contest offer to encourage your website visitors to enter. To enable the promotional banner, type your announcement text in the box and click the <strong>Save and Post</strong> button. The banner will appear above your slides. To disable the banner, click on the trash can button to clear your text.</p>
        <p><strong>Note:</strong> The promotional banner is limited to 120 characters of text.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default AnnouncementForm;
