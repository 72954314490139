import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner as LOADING_ICON } from "@fortawesome/free-solid-svg-icons";

const LOADING_VALUE =  'loading';
const SUCCESS_VALUE =  'success';
const DISABLED_VALUE = true;

const submitStyles = css`
  background-color: #0077cc;
  color: #ffffff;
  text-shadow: 1px 1px rgba(0,0,0,0.25);
  border: none;
`;

const actionStyles = css`
  background-color: #fff;
  color: #0077cc;
  border: none;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
`;

const helpStyles = css`
  background-color: #ffae00;
  color: #fff;
`;

const tourStyles = css`
  background-color: #7d53a3;
  color: #fff;
`;

const destructiveStyles = css`
  background-color: #db3a34;
  color: #fff;
`;

export const disabledValue = ({ loading, success, disabled }) => {
  if (loading)  { return LOADING_VALUE; }
  if (success)  { return SUCCESS_VALUE; }
  if (disabled) { return DISABLED_VALUE; }

  return false;
}

const disabledStyles = {
  [SUCCESS_VALUE]: `
    background-color: #77aa16;
  `,
  [LOADING_VALUE]: `
    background-color: rgb(150, 150, 150);
  `,
  [DISABLED_VALUE]: `
    background-color: rgb(150, 150, 150);
  `,
};

const Element = styled.button`
  ${props => props.type === "submit" ? submitStyles : actionStyles}
  ${props => props.intent === "help" && helpStyles}
  ${props => props.intent === "tour" && tourStyles}
  ${props => (props.intent === "destructive" || props.intent === "dangerous") && destructiveStyles}

  font-family: tondo;
  font-weight: bold;
  border-radius: 24px;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;

  ${props => props.size === "tiny" && `
    padding: 3px 12px;
    font-size: 0.6875em;
  `}

  ${props => props.size === "small" && `
    padding: 6px 12px;
    font-size: 0.875em;
  `}

  ${props => props.size === "medium" && `
    padding: 8px 36px;
    font-size: 1em;
  `}

  ${props => props.$iconOnly && `
    padding: 6px;
  `}

  &:disabled, &.disabled {
    color: #ffffff;
    ${({ disabled }) => disabledStyles[disabled]}
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const IconLayout = styled.span`
  ${props => props.size === "tiny" && `
    margin: 0 6px 0 -3px;
  `}

  ${props => props.size === "small" && `
    margin: 0 6px 0 -3px;
  `}

  ${props => props.size === "medium" && `
    margin: 0 12px 0 -6px;
    font-size: 1.25em;
  `}

  ${props => props.$iconOnly && `
    margin: unset;
  `}
`;

const Icon = ({ icon, $iconOnly, size }) => {
  if (!icon) {
    return null;
  }

  return (
    <IconLayout size={size} $iconOnly={$iconOnly}>
      <FontAwesomeIcon icon={icon} />
    </IconLayout>
  )
};

const showIcon = (icon, disabled) => {
  switch(disabled) {
    case 'loading':
      return LOADING_ICON;
    default:
      return icon && icon;
  };
};

const Button = ({ icon, children, size = 'medium', guarded, disabled, ...rest }) => (
  <Element size={size} disabled={guarded || disabled} className={(guarded || disabled) && 'disabled'} $iconOnly={!children} data-intercom-target={`${children}*`} {...rest}>
    { <Icon icon={showIcon(icon, disabled)} size={size} $iconOnly={!children}/> }
    { children }
  </Element>
);

export default Button;
