import styled from "styled-components";

export const ItemLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const Attributes = styled.div`
  flex: 1;
`;

export const Heading = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  opacity: ${props => props.$hasExpired ? "0.5" : "1"};
  vertical-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Name = styled.span`
  margin-right: 6px;
`;

export const BonusPercent = styled.span`
  background-color: #1d4f90;
  display: inline-block;
  padding: 3px 6px;
  min-width: 96px;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
  font-size: 0.875em;
  color: #ffffff;
`;

export const Expiration = styled.div`
  color: #aaaaaa;
  font-size: 0.875em;
`;
