import { DateTime } from "luxon";
import { any, juxt, pipe, prop, propOr, reject, test } from "ramda";
import { isBlank } from "../../util/presence";
import { PasswordPolicy, charsets } from "password-sheriff";

import DateRange from "../../util/DateRange";

const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const PROTOCOL_FORMAT = /^https?/
const URL_FORMAT = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/

const length = propOr(0, 'length');

export const combineValidations = validations => pipe(
  juxt(validations),
  reject(isBlank),
);

export const dateExclusion = (dateBlacklist, m) => value => {
  const message = m || "This date is unavailable because it conflicts with another campaign";
  const valueObj = Array.isArray(value) ? new DateRange(...value) : DateTime.fromISO(value);

  return dateBlacklist.contains(valueObj) ? message : null;
}

const isNumeric = value => typeof value === "number";

export const greaterThan = min => value =>
  isNumeric(value) && value > min ? null : `Please enter a number greater than ${min}.`;

export const greaterThanOrEqualTo = min => value =>
  isNumeric(value) && value >= min ? null : `Please enter a number greater than or equal to ${min}.`;

export const between = ({ min, max, message }) => value =>
  isNumeric(value) && (value >= min && value <= max) ? null : (message || `Please enter a number between ${min} and ${max}.`);

export const after = (otherProp, message) => (value, context) => {
  const other = prop(otherProp, context);

  if(!other) {
    return null;
  }

  return value > other ? null : message;
};

export const isEmail = value => test(EMAIL_PATTERN, value) ? null : "Please enter an email address."

export const isLink = value => {
  if(!test(PROTOCOL_FORMAT, value)) {
    return  "This must start with https:// (secure connection) or http://";
  }

  if(!test(URL_FORMAT, value)) {
    return "Please enter a valid URL.";
  }

  return null;
};

export const lengthOf = ({ minimum, maximum }) => value => {
  if(minimum && !maximum) {
    if(length(value) < minimum) {
      return `This field must be ${minimum} characters long.`
    }
  }

  if(maximum && !minimum) {
    if(length(value) > maximum) {
      return `This field must be less than ${maximum} characters long.`
    }
  }

  if(length(value) < minimum || length(value) > maximum) {
    return `This field must be be between ${minimum} and ${maximum} characters long.`
  }

  return null;
}

export const numericality = value =>
  !Number.isInteger(value) ? "This field must be a number." : null;


export const presence = value => {
  if(Array.isArray(value)) {
    return any(isBlank, value) ? "This field is required." : null;
  }

  return isBlank(value) ? "This field is required." : null
}

export const presenceIf = predicate => (value, context) =>
  predicate(context) && isBlank(value) ? "This field is required." : null

export const formatOf = ({ pattern, message }) => value => {
  return test(pattern, value) ? null : (message || "This field doesn't match the correct format.");
};

const policy = new PasswordPolicy({
  length: {
    minLength: 8,
  },
  containsAtLeast: {
    atLeast: 3,
    expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters]
  }
});

export const isPassword = value => policy.check(value) ? null : "Please enter a secure password.";

export const optional = validation => (value, context) =>
  isBlank(value) ? null : validation(value, context);

export const inclusionIn = (list, message = "This is not a valid option.") => value =>
  list.includes(value) ? null : message;

export const exclusionFrom = (list, message = "This is not a valid option.") => value =>
  !list.includes(value) ? null : message;
