import React from "react";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";
import { Image } from "cloudinary-react";

import { formatAsDollars } from "../../../util/money";
import { ScheduleMask } from "../../../lib/schedule";
import AccessControlledButton from "../../../lib/AccessControlledButton";
import Button from "../../design/Button";
import WinnerCard from "../../WinnerCard";
import FlashContestDrawingTool from "../../FlashContestDrawingTool";
import { showFlashContestQuery } from "../gql";
import * as UI from "./ui";
import { shortDate } from "../../../util/date";
import InsightCard from "./InsightCard";

const Show = ({ flashContest: partialFlashContest, sweepstakes, showCancel, onCancel, setMode }) => {
  const { data } = useQuery(showFlashContestQuery, {
    variables: {
      sweepstakesId: sweepstakes.id,
      id: partialFlashContest.id,
    }
  });

  const flashContest = pathOr(partialFlashContest, ["sweepstakes", "flashContest"], data);
  const donationLevel = pathOr(null, ["donationLevel"], flashContest);
  const daily = pathOr([], ["insights", "daily", "data"], flashContest);
  const weekly = pathOr([], ["insights", "weekly", "data"], flashContest);

  return (
    <UI.Layout>
      <UI.Header>
        { showCancel && (
          <Button size="small" onClick={onCancel}>❮ Close</Button>
        )}
        <UI.HeaderActions>
          <ScheduleMask enabledPeriods={["review", "open", "closing", "grace", "closed"]}>
            <AccessControlledButton size="small" onClick={() => setMode("edit")} resource="flashContests" permission="U">
              Edit Flash Contest
            </AccessControlledButton>
          </ScheduleMask>
        </UI.HeaderActions>
      </UI.Header>

      <UI.Body>
        <UI.Heading>
          <UI.Name>{flashContest.name}</UI.Name>
          <UI.Date>
            From {shortDate(sweepstakes, flashContest.beginsAt)} to {shortDate(sweepstakes, flashContest.endsAt)}
          </UI.Date>
        </UI.Heading>

        <UI.Insights>
          <InsightCard daily={daily} weekly={weekly} />
        </UI.Insights>

        <UI.Field>
          <UI.Label>Announcement</UI.Label>
          <UI.Value>{flashContest.announcement}</UI.Value>
        </UI.Field>

        {donationLevel && (
          <UI.Field>
            <UI.Label>Prize Entries</UI.Label>
            <UI.Value>{`${formatAsDollars(donationLevel.price)} worth of entries (${donationLevel.rewardTickets})`}</UI.Value>
          </UI.Field>
        )}

        { flashContest.image && (
          <UI.Field>
            <UI.Label>Image</UI.Label>
            <UI.Value>
              <Image {...flashContest.image} width="300" aspectRatio="16:9" fetchFormat="auto" />
            </UI.Value>
          </UI.Field>
        )}

        { flashContest.caption && (
          <UI.Field>
            <UI.Label>Caption</UI.Label>
            <UI.Value>{flashContest.caption}</UI.Value>
          </UI.Field>
        )}

        { flashContest.notes && (
          <UI.Field>
            <UI.Label>Notes</UI.Label>
            <UI.Value>{flashContest.notes}</UI.Value>
          </UI.Field>
        )}

        { flashContest.winningTicket && flashContest.winningEntry && (
          <UI.Field>
            <UI.Label>Winner</UI.Label>
            <WinnerCard prize={flashContest} grand detailed />
          </UI.Field>
        )}
      </UI.Body>

      <UI.Footer>
        <UI.FooterActions>
          <FlashContestDrawingTool flashContest={flashContest} sweepstakes={sweepstakes} />
        </UI.FooterActions>
      </UI.Footer>
    </UI.Layout>
  );
}

export default Show;
