import React from "react";

import { useMasterDetailContext } from "../../../lib/MasterDetail";
import { shortDate } from "../../../util/date";
import * as UI from "./ui";

const Item = ({ item: thankYouCampaign }) => {
  const { sweepstakes } = useMasterDetailContext();
  const { name } = thankYouCampaign;

  return (
    <UI.ItemLayout>
      <UI.Attributes>
        <UI.Heading>
          <UI.Name>{name}</UI.Name>
        </UI.Heading>
        <UI.Range>
          {shortDate(sweepstakes, thankYouCampaign.beginsAt)} - {shortDate(sweepstakes, thankYouCampaign.endsAt)}
        </UI.Range>
      </UI.Attributes>
    </UI.ItemLayout>
  );
}

export default Item;
