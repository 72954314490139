import React from "react";
import { isEmpty } from "ramda";

import useField from "../../lib/useField";
import * as UI from "./ui";

// Browsers do not always trigger change events for input's with a type="number",
// even if the input box shows that the user entered something invalid. For this
// reason, we use the text type and hint at the value using inputMode.
//
// https://github.com/facebook/react/issues/16554

const NumberField = ({id, label, ...rest}) => {
  const { value, errors, showErrors, skeleton, setValue, touch } = useField(id);

  const setWithCoercion = event => {
    // Capture event value early, as they are re-used.
    const eventValue = event.target.value;

    if(isEmpty(eventValue)) {
      setValue({target: {value: ""}});
    } else {
      const parsed = parseInt(eventValue, 10);
      // Default to original value if we couldn't parse the new input.
      const newValue = isNaN(parsed) ? value : parsed;
      setValue({target: { value: newValue } });
    }
  }

  return (
    <UI.FieldLayout>
      <UI.Input
        id={id}
        name={id}
        type="text"
        inputMode="numeric"
        onChange={setWithCoercion}
        onBlur={touch}
        value={value}
        disabled={skeleton}
        $invalid={showErrors}
        data-intercom-target={`${id}*`}
        {...rest}
      />
      <UI.Label htmlFor={id}>{label}</UI.Label>
      { showErrors && <UI.Errors>{errors}</UI.Errors> }
    </UI.FieldLayout>
  );
};

export default NumberField;
