import React from "react";

import Button from "../../components/design/Button";
import * as UI from "./ui";

let timerID;

const Search = ({ search, searchTerm, setSearchTerm, filter, setFilter }) => {
  const onFilterChange = searchFilter => () => {
    setFilter(searchFilter);

    if(timerID) {
      clearTimeout(timerID);
    };

    if(search.auto) {
      search.onSubmit({
        value: searchTerm,
        filter: searchFilter,
      });
    }
  };

  const onSubmit = e => {
    if(search.onSubmit) {
      if(e) {
        e.preventDefault();
      }

      if(timerID) {
        clearTimeout(timerID);
      };

      search.onSubmit({ value: searchTerm, filter });
    }
  };

  const onChange = e => {
    const nextSearchTerm = e.target.value;
    setSearchTerm(nextSearchTerm);

    if(search.auto) {
      if(timerID) {
        clearTimeout(timerID);
      };

      timerID = setTimeout(() => {
        search.onSubmit({ value: nextSearchTerm, filter });
      }, 1000);
    }
  };

  return (
    <UI.SearchLayout onSubmit={onSubmit}>
      { search.filters && (
        <UI.FilterLayout>
          { search.filters.map(searchFilter => (
            <UI.Filter key={searchFilter.id} $selected={filter.id === searchFilter.id} onClick={onFilterChange(searchFilter)}>
              {searchFilter.name}
            </UI.Filter>
          ))}
        </UI.FilterLayout>
      )}
      <UI.SearchInput
        placeholder={search.placeholder}
        value={searchTerm}
        onChange={onChange}
      />
      { !search.auto && <Button size="small" type="submit">Search</Button> }
    </UI.SearchLayout>
  );
};

export default Search;
