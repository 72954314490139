import styled from "styled-components";

export const ItemLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const Attributes = styled.div`
  flex: 1;
`;

export const Heading = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  vertical-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Name = styled.span`
  margin-right: 6px;
`;

export const Range = styled.div`
  font-size: 0.875em;
  color: #aaaaaa;
  display: inline-block;
  margin-right: 10px;
`;
