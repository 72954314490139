import React from "react";
import { DateTime } from "luxon";
import { evolve } from "ramda";

import DateBlacklist from "../../util/DateBlacklist";
import { default as LibraryForm } from "../forms/Form";
import TextField from "../forms/TextField";
import DateRangeField from "../forms/DateRangeField";
import Button from "../design/Button";
import { combineValidations, presence, formatOf, dateExclusion, exclusionFrom } from "../../lib/validations";
import * as UI from "./ui";

const DEFAULT_VALIDATIONS = {
  range: presence,
  promoCodeName: formatOf({ pattern: /^[A-Za-z0-9_-]+$/, message: "This field must only contain letters, numbers, hyphens, or underscores."  }),
};

const Form = ({ sweepstakes, promoCodes = [], initialInputObject, onSubmit, hasBegun, zone, loading, guarded }) => {
  const dateBlackList = new DateBlacklist(
    sweepstakes
      .thankYouCampaigns.filter((thankYouCampaign) => thankYouCampaign.id !== initialInputObject.id)
      .map((thankYouCampaign) => [thankYouCampaign.beginsAt, thankYouCampaign.endsAt])
  );

  const existingPromoCodeNames = promoCodes.map(promoCode => promoCode.name).filter(name => name !== initialInputObject.promoCodeName);

  const validations = evolve({
    range: validation => combineValidations([validation, dateExclusion(dateBlackList, "This Thank You Campaign cannot overlap with an existing one")]),
    promoCodeName: validation => combineValidations([validation, exclusionFrom(existingPromoCodeNames, "A Promo Code with this name already exists, please choose another.")]),
  }, DEFAULT_VALIDATIONS);

  const minimumStartDate = sweepstakes.beginsAt ? DateTime.fromISO(sweepstakes.beginsAt, { zone: sweepstakes.timezone }).toISODate() : null;
  const maximumEndDate = sweepstakes.endsAt ? DateTime.fromISO(sweepstakes.endsAt, { zone: sweepstakes.timezone }).toISODate() : null;

  return (
    <LibraryForm initialInputObject={initialInputObject} onSubmit={onSubmit} validations={validations}>
      <UI.FieldGroup>
        <TextField id="promoCodeName" label="Promo Code Name" disabled={guarded || hasBegun} />
      </UI.FieldGroup>
      <DateRangeField
        id="range"
        label="Campaign Dates"
        zone={zone}
        disabled={guarded || hasBegun}
        min={minimumStartDate}
        max={maximumEndDate}
      />
      <TextField id="notes" label="Notes" />
      <Button type="submit" disabled={loading || guarded}>Save Thank You Campaign</Button>
    </LibraryForm>
  );
};

export default Form;
