import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";
import { Helmet } from "react-helmet";

import Header from "./Header";
import SweepstakesList from "./SweepstakesList";
import { setupDashboardQuery } from "./gql";
import * as UI from "./ui";

const Dashboard  = () => {
  const { nonprofitId } = useParams();
  const { data } = useQuery(setupDashboardQuery, {
    variables: {
      nonprofitId,
    }
  });

  const sweepstakes = pathOr([], ['nonprofit', 'sweepstakes'], data);

  return (
    <React.Fragment>
      <Helmet>
        <title>TapKat Dashboard</title>
      </Helmet>
      <UI.Layout>
        <Header hasSweepstakes={sweepstakes.length > 0} />
        { sweepstakes.length > 0 && (
          <SweepstakesList sweepstakes={sweepstakes} />
        )}
      </UI.Layout>
    </React.Fragment>
  );
}

export default Dashboard;
