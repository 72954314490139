import React from "react";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from "draftail";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeading, faListUl, faListOl, faBold, faItalic } from '@fortawesome/free-solid-svg-icons'
import { pipe } from "ramda";

import "draft-js/dist/Draft.css"
import "draftail/dist/draftail.css"

import useField from "../../lib/useField";
import * as UI from "./ui";

const importMarkdown = pipe(markdownToDraft, convertFromRaw, EditorState.createWithContent);
const exportMarkdown = pipe(convertToRaw, draftToMarkdown);

const BLOCK_TYPES = [
  {
    type: BLOCK_TYPE.HEADER_ONE,
    icon: <FontAwesomeIcon icon={faHeading} />,
  },
  {
    type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
    icon: <FontAwesomeIcon icon={faListUl} />,
  },
  {
    type: BLOCK_TYPE.ORDERED_LIST_ITEM,
    icon: <FontAwesomeIcon icon={faListOl} />,
  },
];

const INLINE_STYLES = [
  {
    type: INLINE_STYLE.BOLD,
    icon: <FontAwesomeIcon icon={faBold} />,
  },
  {
    type: INLINE_STYLE.ITALIC,
    icon: <FontAwesomeIcon icon={faItalic} />,
  },
];

const RichTextareaField = ({id, label, ...rest}) => {
  const { value, errors, showErrors, setValue } = useField(id);
  const [ editorState, setEditorState ] = React.useState(() => importMarkdown(value));

  const onChange = editorState => {
    setEditorState(editorState);
    const synthenticChangeEvent = {
      target: {
        value: exportMarkdown(editorState.getCurrentContent()),
      }
    };

    setValue(synthenticChangeEvent);
  }

  return (
    <UI.FieldLayout data-intercom-target={`${id}*`}>
      <UI.EditorLayout>
        <UI.EditorStyles>
          <DraftailEditor
            id={id}
            name={id}
            editorState={editorState}
            onChange={onChange}
            blockTypes={BLOCK_TYPES}
            inlineStyles={INLINE_STYLES}
            {...rest}
          />
        </UI.EditorStyles>
      </UI.EditorLayout>
      <UI.Label htmlFor={id}>{label}</UI.Label>
      { showErrors && <UI.Errors>{errors}</UI.Errors> }
    </UI.FieldLayout>
  );
};

export default RichTextareaField;
