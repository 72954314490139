import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { evolve, pipe, pick, isNil } from "ramda";

import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import StateSelectField from "../../forms/StateSelectField";
import CountrySelectField from "../../forms/CountrySelectField";
import Button from "../../design/Button";
import { updateSweepstakesMutation } from "./gql";
import * as UI from "./ui";
import { presence } from "../../../lib/validations";
import { Context } from "../../../lib/Snackbar";

const validations = {
  "address.line1": presence,
  "address.city": presence,
  "address.state": presence,
  "address.postalCode": presence,
  "address.country": presence,
};

const buildInitialInputObject = pipe(
  pick(['address']),
  evolve({
    address: pick(['name','line1', 'line2', 'city', 'state', 'postalCode', 'country']),
  }),
);

const MailInAddressForm = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateSweepstakes ] = useMutation(updateSweepstakesMutation)

  const onSubmit = attributes => (
    updateSweepstakes({
      variables: {
        id: sweepstakes.id,
        attributes,
      }
    }).then(({ data }) => {
      if(isNil(data.updateSweepstakes.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(sweepstakes)} onSubmit={onSubmit} validations={validations}>
          <TextField id="address.name" label="Mail-in Address Name" />
          <UI.FieldGroup>
            <TextField id="address.line1" label="Address 1" />
            <TextField id="address.line2" label="Address 2" />
          </UI.FieldGroup>
          <UI.FieldGroup>
            <TextField id="address.city" label="City" />
            <StateSelectField id="address.state" label="State" />
            <TextField id="address.postalCode" label="Zip" />
            <CountrySelectField id="address.country" label="Country" disabled />
          </UI.FieldGroup>
          <Button type="submit">Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Mail-in Address</h2>
        <p>Some donors prefer to send their donations in the mail. By default, this is set to the address you provided for your nonprofit during sign-up. Change this to an address at which you can receive mail.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
}

export default MailInAddressForm;
