import { useState, useCallback } from"react";
import { useDropzone } from "react-dropzone";
import { useAuth0 } from "@auth0/auth0-react";
import { isNil } from "ramda";

import { generateSigningFunction, upload } from "./util";

const useUploadWidget = ({ uploadPreset, folder, onUpload, disabled }) => {
  const [ uploadProgress, setUploadProgress ] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const onUploadProgress = useCallback(progressEvent => {
    if(progressEvent.lengthComputable) {
      const percent = Math.round((progressEvent.loaded * 100.00) / progressEvent.total);
      setUploadProgress(percent);
    }
  }, [ setUploadProgress ]);

  const onDrop = useCallback(acceptedFiles => {
    if (disabled) {
      return;
    }
    const [ file ] = acceptedFiles;

    if(isNil(file)) {
      return null;
    }

    const config = {
      uploadPreset,
      folder,
    };

    setUploadProgress(0);
    (async () => {
      const authToken = await getAccessTokenSilently();
      const signUpload = generateSigningFunction(authToken);

      const response = await upload(file, {
        config,
        signUpload,
        onUploadProgress,
      });

      setUploadProgress(null);
      if(onUpload) { 
        onUpload(response.data);
      }
    })();
  }, [ getAccessTokenSilently, folder, onUpload, onUploadProgress, uploadPreset, disabled ]);

  return {
    ...useDropzone({
      accept: {
        "image/*": [],
      },
      multiple: false,
      noClick: true,
      noKeyboard: true,
      onDrop,
    }),
    uploadProgress,
  };
};

export default useUploadWidget;
