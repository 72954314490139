import React from "react";

import * as UI from "./ui";

const Hints = () => (
  <UI.Layout>
    <UI.Title>Getting Started</UI.Title>
    <UI.Paragraph>Thank You Campaigns are limited time promotions designed to thank people who have just made a donation with an offer to enter again and receive 2X entry numbers. A Thank You Campaign image with your promo code link appears on the Thank You webpage that donors see immediately after their transaction is completed. Additionally, the same Thank You Campaign image with link appears on their donation email receipt.</UI.Paragraph>

    <UI.HintList>
      <UI.Hint><strong>Click “Add Thank You Campaign”</strong>: This will create a new compaign.</UI.Hint>
      <UI.Hint><strong>Promo Code Name</strong>: Thank You Campaigns always feature a promo code with a bonus percentage of 100% (2x entry numbers). Enter the name of your promo code here and it will also be added to your Promo Code list.</UI.Hint>
      <UI.Hint><strong>Set Start and End Date</strong>: Start dates begin at 12:00am and the end date will end at 11:59pm of your sweepstakes time zone.</UI.Hint>
      <UI.Hint><strong>Campaign Images</strong>: These are generated for you using the first slide of your sweepstakes and a text overlay of the campaign details. We generate two images, one for small devices and one for larger devices.</UI.Hint>
    </UI.HintList>
  </UI.Layout>
);

export default Hints;
