import { gql } from "@apollo/client";

export const thankYouCampaignFragment = gql`
  fragment ThankYouCampaignFragment on ThankYouCampaign {
    id
    name
    notes
    beginsAt
    endsAt
    promoCode {
      id
      name
      bonusPercent
      expiresAt
    }
  }
`

export const thankYouCampaignsQuery = gql`
  query ThankYouCampaignsQuery($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      timezone
      beginsAt
      endsAt
      thankYouCampaigns {
        ...ThankYouCampaignFragment
      }
    }
  }
  ${thankYouCampaignFragment}
`;

export const showThankYouCampaignQuery = gql`
  query ShowThankYouCampaignQuery($sweepstakesId: SweepstakesSlug!, $id: ID!) {
    sweepstakes(id: $sweepstakesId) {
      id
      timezone
      beginsAt
      endsAt
      thankYouCampaign(id: $id) {
        ...ThankYouCampaignFragment
        smallImageUrl
        largeImageUrl
      }
    }
  }
  ${thankYouCampaignFragment}
`;

export const createThankYouCampaignMutation = gql`
  mutation CreateThankYouCampaign($sweepstakesId: SweepstakesSlug!, $attributes: ThankYouCampaignInput!) {
    createThankYouCampaign(sweepstakesId: $sweepstakesId, attributes: $attributes) {
      thankYouCampaign {
        ...ThankYouCampaignFragment,
      }
      error {
        code
        message
      }
    }
  }
  ${thankYouCampaignFragment}
`;

export const updateThankYouCampaignMutation = gql`
  mutation updateThankYouCampaign($id: ID!,$attributes: ThankYouCampaignInput!) {
    updateThankYouCampaign(id: $id, attributes: $attributes) {
      thankYouCampaign {
        ...ThankYouCampaignFragment,
      }
      error {
        code
        message
      }
    }
  }
  ${thankYouCampaignFragment}
`;

export const promoCodesQuery = gql`
  query PromoCodesQuery($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      promoCodes {
        id
        name
      }
    }
  }
`;
