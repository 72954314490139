import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OverviewHeading = styled.div`
  font-size: 1.125em;
  font-weight: bold;
  margin: 0 0 6px 6px;
  color: #333333;
`;

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
  margin-bottom: 12px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Step = styled(Link)`
  border-radius: 12px;
  display: flex;
  text-decoration: none;
  color: #333333;
  flex: 1;
  min-width: 50%;
  background-color: #ffffff;
`;

const iconColor = ({ $isComplete }) => $isComplete ? '#77aa16' : '#cccccc';

export const Icon = styled.div`
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${iconColor};
  font-size: 1.5em;
`;

export const Info = styled.div`
  flex: 1;
  padding: 12px 24px;
`;

export const Name = styled.div`
  font-size: 0.875em;
  font-weight: bold;
  margin-bottom: 6px;
`;

export const Description = styled.div`
  font-size: 0.75em;
`;

export const NavLayout = styled.div`
  margin-bottom: 12px;
  padding: 6px 12px;
  font-size: 0.875em;
`;

export const NavBack = styled(Link)`
`;

export const NavControlIcon = styled(FontAwesomeIcon)`
`;
