import styled from "styled-components";
import { Link } from "react-router-dom";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
`;

export const Header = styled.header`
  display: flex;
  padding: 0 6px;
`;

export const HeaderActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 18px;
  }
`;

export const Body = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 6px;
`;

export const Heading = styled.div`
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr auto;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const Name = styled.div`
  font-size: 1.75em;
  font-weight: bold;
  color: #333333;
  font-family: tondo, sans-serif;
`;

export const Date = styled.div`
  font-size: 0.875em;
`;

export const Field = styled.div`
  margin-bottom: 24px;
  padding: 0 6px;
`;

export const SmallImage = styled.img`
  max-width: 100%;

  @media (min-width: 480px) {
    max-width: 50%;
  }
`;

export const LargeImage = styled.img`
  max-width: 100%;
`;

export const Label = styled.div`
  color: #666666;
  font-size: 0.75em;
`

export const Value = styled.div`
  color: #333333;
  padding-left: 6px;
`;

export const ValueLink = styled(Link)`
`;

export const Actions= styled.div`
  margin: 12px -6px;
`;
