import { gql } from "@apollo/client";

export const flashContestFragment = gql`
  fragment FlashContestFragment on FlashContest {
    id
    name
    announcement
    beginsAt
    endsAt
    image {
      publicId
      version
      cloudName
      crop
      gravity
      background
    }
    caption
    notes
    eligibleRange
    locked
    donationLevel {
      id
    }
  }
`

export const flashContestsQuery = gql`
  query FlashContestsQuery($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      timezone
      beginsAt
      endsAt
      donationLevels {
        id
        level
        price
        rewardTickets
      }
      flashContests {
        ...FlashContestFragment
      }
    }
  }
  ${flashContestFragment}
`;

export const showFlashContestQuery = gql`
  query ShowFlashContestQuery($sweepstakesId: SweepstakesSlug!, $id: ID!) {
    sweepstakes(id: $sweepstakesId) {
      id
      timezone
      flashContest(id: $id) {
        ...FlashContestFragment
        donationLevel {
          id
          rewardTickets
          price
        }
        flashContestWin {
          id
          confirmed
        }
        winningTicket {
          id
          number
        }
        winningEntry {
          id
          firstName
          lastName
          email
          phoneNumber
          address {
            city
            state
          }
        }
        insights {
          weekly: performanceTimeline(resolution: WEEKLY) {
            data {
              bucket
              total
              count
              average
            }
          }
          daily: performanceTimeline(resolution: DAILY) {
            data {
              bucket
              total
              count
              average
            }
          }
        }
      }
    }
  }
  ${flashContestFragment}
`;

export const createFlashContestMutation = gql`
  mutation CreateFlashContest($sweepstakesId: SweepstakesSlug!, $attributes: FlashContestInput!) {
    createFlashContest(sweepstakesId: $sweepstakesId, attributes: $attributes) {
      flashContest {
        ...FlashContestFragment,
      }
      error {
        code
        message
      }
    }
  }
  ${flashContestFragment}
`;

export const updateFlashContestMutation = gql`
  mutation updateFlashContest($id: ID!,$attributes: FlashContestInput!) {
    updateFlashContest(id: $id, attributes: $attributes) {
      flashContest {
        ...FlashContestFragment,
      }
      error {
        code
        message
      }
    }
  }
  ${flashContestFragment}
`;
