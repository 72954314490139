import React, { useState, useEffect } from "react";

import useField from "../../lib/useField";
import * as UI from "./ui";

const DateRangeField = ({id, label, zone, min, max, ...rest}) => {
  const { value, errors, showErrors, skeleton, setValue, touch } = useField(id);
  const [ touchFrom, setTouchFrom ] = useState(false);
  const [ touchTo, setTouchTo ] = useState(false);

  const [fromDate, toDate] = value;

  const onFromChange = e => setValue({ target: { value: [e.target.value, toDate] }});
  const onToChange = e => setValue({ target: { value: [fromDate, e.target.value] }});

  useEffect(() => {
    if(touchFrom && touchTo) {
      touch();
    }
  }, [touch, touchFrom, touchTo]);

  return (
    <UI.FieldLayout>
      <UI.Input
        id={id}
        type="date"
        name={id}
        onChange={onFromChange}
        onBlur={() => setTouchFrom(true)}
        value={fromDate}
        disabled={skeleton}
        $invalid={showErrors}
        min={min}
        max={toDate || max}
        data-intercom-target={`${id}-from*`}
        {...rest}
      />
      <UI.Input
        id={id}
        type="date"
        name={id}
        onChange={onToChange}
        onBlur={() => setTouchTo(true)}
        value={toDate}
        disabled={skeleton}
        $invalid={showErrors}
        min={fromDate || min}
        max={max}
        data-intercom-target={`${id}-to*`}
        {...rest}
      />
      <UI.Label htmlFor={id}>{label}</UI.Label>
      { showErrors && <UI.Errors>{errors}</UI.Errors> }
    </UI.FieldLayout>
  );
};

export default DateRangeField;
