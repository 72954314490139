import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  gap: 12px;
`;

export const MasterLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;

  ${props => props.$mode === 'detail-only' && `
    opacity: 0;
    pointer-events: none;
  `}
`;

export const Lock = styled.div`
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 100ms ease-out;
  backdrop-filter: blur(2px);

  ${props => props.$locked && `
    pointer-events: auto;
    opacity: 1;
  `}
`;

export const MasterList = styled.ol`
  list-style-type: none;
  margin: 0;
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 6px;
`;

export const MasterItem = styled.li`
  padding: 6px 12px;
  transition: padding-left 100ms linear, margin 100ms linear;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;

  &:not(:last-child) {
    margin-bottom: 3px;
  }

  ${props => !props.$selected && `
    &:hover {
      padding-left: 18px;
    }
  `}

  ${props => props.$selected && `
    padding-left: 24px;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  `}
`;

export const DetailLayout = styled.div`
  display: flex;
  flex-direction: column;
  transition: transform 150ms ease-in-out;

  ${props => props.mode === 'side-by-side' && `
    flex: 1 1 0;
  `}

  ${props => props.mode === 'master-only' && `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
  `}

  ${props => props.mode === 'detail-only' && `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
  `}
`;

export const DetailBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1 0;
`;

export const SearchLayout = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const SearchInput = styled.input`
  flex: 1;
  font-size: 0.875em;
  border-radius: 6px;
  border: none;
  padding: 8px;
  outline: none;
`;

export const FilterLayout = styled.ol`
  min-width: 100%;
  list-style-type: none;
  display: flex;
`;

export const Filter = styled.li`
  color: #333333;
  font-size: 0.75em;
  display: inline-block;
  padding: 3px 12px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 250ms ease-out;

  ${props => props.$selected && `
    background-color: rgba(255, 255, 255, 1);
  `}

  @media (max-width: 768px) {
    padding: 3px 9px;
  }
`

export const MasterFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 0 12px;
`;
