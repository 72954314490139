import React from "react";
import { useParams } from "react-router-dom";

import { withAccessControl } from "../../lib/permissions";
import ThankYouCampaignList from "../ThankYouCampaignList";

const ThankYouCampaignPage = () => {
  const { sweepstakesId } = useParams();

  return (
    <React.Fragment>
      <ThankYouCampaignList sweepstakesId={sweepstakesId} />
    </React.Fragment>
  );
}

export default withAccessControl({ resource: "thankYouCampaigns" })(ThankYouCampaignPage);
