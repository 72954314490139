import React from "react";

import { useMasterDetailContext } from "../../../lib/MasterDetail";
import Redactable from "../../../lib/Redactable";
import { formatAsDollars } from "../../../util/money";
import { shortDateTime } from "../../../util/date";
import * as UI from "./ui";

const Item = ({ item: entry }) => {
  const { sweepstakes } = useMasterDetailContext();

  return (
    <UI.ItemLayout>
      <UI.EntrantLayout>
        <UI.Name>
          <Redactable>{entry.firstName} {entry.lastName}</Redactable>
        </UI.Name>
        <UI.Email>
          <Redactable>{entry.email}</Redactable>
        </UI.Email>
      </UI.EntrantLayout>
      <UI.EntryDataLayout>
        <UI.Amount amount={entry.amount} status={entry.status}>
          {entry.status === 'free' ? "AMOE" : formatAsDollars(entry.amount) }
        </UI.Amount>
        <UI.Date>{shortDateTime(sweepstakes, entry.createdAt)}</UI.Date>
      </UI.EntryDataLayout>
    </UI.ItemLayout>
  );
}

export default Item;
