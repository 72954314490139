import React from "react";
import { find, propEq, isNil } from "ramda";

import RoleDescription from "../../RoleDescription";
import useField from "../../../lib/useField";

const CurrentRoleDescription = ({ id, roles }) => {
  const { value } = useField(id);
  const currentRole = find(propEq(value, 'id'), roles);

  if(isNil(currentRole)) {
    return null;
  }

  return (
    <RoleDescription role={currentRole} subject="This team member" />
  );
};

export default CurrentRoleDescription;
