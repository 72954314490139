import { useContext, useState, useEffect, useRef } from "react";
import { complement, defaultTo, either, isNil, isEmpty } from "ramda";

import FormContext from "./FormContext";

const isPresent = complement(either(isNil, isEmpty));

const useField = (id, targetAttribute = "value") => {
  const {
    values,
    errors,
    setField,
    skeleton,
    formTouched,
  } = useContext(FormContext);

  const [ touched, setTouched ] = useState(false);

  const fieldValue = defaultTo("", values[id]);
  const fieldErrors = errors[id];
  const setValue = setField(id, targetAttribute);
  const renderErrors = touched || formTouched;

  // We must keep track of the formTouch value in the field so that if the form resets,
  // we are able to force this field back into an untouched state.
  const formTouchedRef = useRef();
  useEffect(() => { formTouchedRef.current = formTouched })
  const prevFormTouched = formTouchedRef.current;
  useEffect(() => {
    if(prevFormTouched && !formTouched) {
      setTouched(false)
    }
  }, [prevFormTouched, formTouched])

  return {
    value: fieldValue,
    errors: fieldErrors,
    showErrors: renderErrors && isPresent(fieldErrors),
    skeleton,
    setValue,
    touched,
    touch: () => setTouched(true),
  };
}

export default useField;
