import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { pick, isNil } from "ramda";

import { updateSweepstakesMutation } from "./gql";
import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import RichTextareaField from "../../forms/RichTextareaField";
import Button from "../../design/Button";
import { Context } from "../../../lib/Snackbar";
import * as UI from "./ui";

const buildInitialInputObject = pick([
  'headline',
  'description',
  'prizeDetails',
])

const SweepstakesTextForm = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateSweepstakes ] = useMutation(updateSweepstakesMutation)

  const onSubmit = inputObject => (
    updateSweepstakes({
      variables: {
        id: sweepstakes.id,
        attributes: inputObject,
      }
    }).then(({ data }) => {
      if(isNil(data.updateSweepstakes.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(sweepstakes)} onSubmit={onSubmit}>
          <TextField id="headline" label="Sweepstakes Headline" />
          <RichTextareaField id="description" label="Description" />
          <RichTextareaField id="prizeDetails" label="Prize Details" />
          <Button type="submit">Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Writing your Sweepstakes Text</h2>
        <p>The <strong>Sweepstakes Headline</strong> appears at the top of the page above the main slide show.</p>
        <p>Your <strong>Sweepstakes Description</strong> appears below the main slide show. We recommend 2 - 3 paragraphs of copy about the sweepstakes prize.</p>
        <p>The <strong>Prize Details</strong> are optional. Use this section for details and specifications of the prize and any applicable conditions and restrictions.</p>
        <p><strong>Note:</strong> These values can be changed at any point during the sweepstakes.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default SweepstakesTextForm;
